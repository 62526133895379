.highlight {
  margin: 80px 0;
  background: $color-black;
  padding: 55px 40px;

  &__title {
    margin: 0;
  }

  .rich-text {
    font-size: $font-size-normal;
  }

  &--inverted {
    background-color: $color-yellow;
    color: $color-black;

    a {
      color: $color-black;

      &.button--secondary {
        color: $color-white;
      }
    }
  }

  &--inline {
    margin: 0;
  }
}

.highlights-list {
  .highlight {
    margin: 0 0 3px;
  }
}

.double-highlight {
  margin: 80px 0;
}

@include media(">=tablet") {
  .highlight {
    padding: 55px 60px;
    display: flex;
    justify-content: space-between;

    &__title {
      flex: 1 1 50%;
      padding-right: 30px;
      //min-width: 300px;
      white-space: pre-wrap;
      font-size: $font-size-huge;
    }
    &__content {
      flex: 1 1 50%;
      //max-width: 75%;

      .rich-text > :first-child {
        margin-top: 0;
      }
    }
  }

  .highlights-list {
    $offset: 160px;

    margin: 80px -50px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: $offset;

    .highlight {
      display: block;
      margin: 0 0 3px;
      width: calc(50% - 1.5px);

      &__title {
        padding-right: 0;
      }

      &:nth-child(even) {
        transform: translateY($offset);
      }
    }
  }
}

@include media(">=tablet-landscape") {
  .highlight {
    margin: 80px -50px;

    &--inline {
      margin: 0;
    }

    &--offset-less {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    &:not(&--small) &__title {
      min-width: 350px;
    }
  }

  // highlight directly in wide content:
  .content--wide > .highlight {
    margin-left: 0;
    margin-right: 0;

    .highlight__title {
      max-width: 300px;
    }
  }

  .content--wide > .double-highlight {
    display: flex;

    & > .highlight {
      flex: 1 1 50%;
    }
  }
}
