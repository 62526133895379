@keyframes loading {
  to { transform: rotateY(180deg); }
}

.pagination {
  font-size: $font-size-large;
  font-weight: bold;

  &__link {
    position: relative;

    &::before {
      position: absolute;
      right: 100%;
      margin-right: 20px;
      font-size: 1.85rem;
      line-height: 1.1;
      content: "+";
    }

    &[data-loading] {
      cursor: default;
      &::before {
        animation: loading 1s ease-out infinite;
      }
    }

    &--previous {
      margin-right: 2em;

      @at-root .js & {
        display: none;
      }
    }
  }
}
