.filters {
  margin: 0 -35px 2rem;
  font-size: $font-size-large;
  font-weight: bold;

  &__title {
    margin-left: 30px;
    font-size: $font-size-small;
    color: rgba($color-white, 0.5);
  }

  &__container { // not always used but a <ul>
    @include list;
  }

  &__header {
    position: absolute;
    top: 2rem;
    left: 30px;
    right: 30px;
    font-size: $font-size-larger;

    a {
      font-weight: bold;
      color: inherit;
      text-decoration: none;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }

  &__group-name {
    padding: 10px 0 10px 35px;

    &::before {
      top: 10px;
    }
  }

  &__link {
    display: block;
    padding: 10px 0 10px 30px;
    font-size: $font-size-large;
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }

    &--selected {
      background-color: $color-yellow;
      color: $color-black;
    }
  }

  &__list {
    @include list($margin: 0 0 1rem);

    &--sublist {
      margin-left: 0;
    }
  }

  &__list--sublist &__link {
    padding-left: 70px;
    font-size: $font-size-larger;
  }

  &__toggle,
  &__close {
    display: none;
  }

  @include media("<tablet-landscape") {
    margin: 0 0 2rem;
    transition: padding-top 0.3s ease-in-out;

    &__title {
      //transition: margin 0.3s ease-in-out;
    }

    &__toggle-container {
      width: 100%;
      background-color: transparent;
      transition: 0.3s ease-in-out;
      transition-property: width, background-color;
    }

    &--sticky-toggle {
      padding-top: 102px;
    }

    @at-root &--sticky-toggle &__toggle-container {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      //transform: translateX(-20px);
      width: 100vw;
      background-color: $color-yellow;

      .filters__title {
        margin: 35px 0 0 35px;
        color: rgba($color-black, 0.5);
      }
    }

    &__container {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 100%;
      height: 100vh;
      z-index: 5;
      padding: 100px 30px 0;
      overflow: auto;
      transform: translateY(0);
      transition: transform 0.3s ease-in-out;
      background: $color-mineshaft;
      display: flex;
      flex-flow: column;

      &:target {
        transform: translateY(100%);
      }

      &::before {
        margin-left: 35px;
        font-weight: normal;
        font-size: $font-size-normal;
        color: rgba($color-white, 0.5);
        content: attr(data-title);
      }
    }

    &__toggle {
      display: block;
    }

    &__close {
      display: inline;
      top: 30px;
      font-size: $font-size-normal;
      text-transform: lowercase;
    }
  }

  @include media(">=tablet-landscape") {
    margin-right: 0;

    &__header {
      display: none;
    }

    &__title {
      margin-top: 2.5rem;
      margin-left: 35px;
    }
    &__link {
      padding-left: 35px;
    }
  }
}
