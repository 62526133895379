.location {
  &__poster { // figure
    position: relative;

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }

  &__poster-caption { // figcaption
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
  }

  &__select-button { // button
    outline: none;
    border: 0;
    background-color: rgba($color-black, 0.5);
    padding: 0.5em 1em;
    line-height: 25px;
    font-weight: bold;
    font-size: $font-size-larger;
    color: $color-white;
    transition: background-color 0.5s ease-in-out;
    cursor: pointer;

    &::after {
      display: inline-block;
      vertical-align: top;
      width: 16px;
      height: 11px;
      margin-left: 5px;
      font-size: $font-size-large;
      content: "+";
    }

    &--selected {
      //background-color: rgba(255, 255, 255, 0.25);

      &::after {
        border: solid currentColor;
        border-width: 0 0 3px 3px;

        transform: rotate(-45deg);
        content: "";
      }
    }
  }

  &__footer {
    margin: 80px 0;
  }

  &__selection { // form
    margin: 0;

    &-list { // ul
      @include list($margin: 50px 0);
      display: flex;
      flex-flow: row wrap;

      > li {
        position: relative;
        overflow: hidden;
        margin: 0 10px 10px 0;
      }
    }

    &-check { // label
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      width: 50px;
      height: 50px;
      padding-left: 50px;
      white-space: nowrap;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color-white, 0.5);
        content: "";
      }

      &::after {
        display: block;
        position: absolute;
        top: 25px;
        right: 25px;
        width: 19px;
        height: 12px;
        border: solid $color-white;
        border-width: 0 0 3px 3px;
        transform: translate(50%, -65%) rotate(-45deg);
        content: "";
      }
    }
    &-input {
      position: absolute;
      left: 0;
      bottom: 100%;
    }
    &-input:not(:checked) + &-check {
      opacity: 0.3;
    }
  }

  &-showcase {
    margin: 0 0 30px;
    padding: 0 20px 30px 0;
    overflow: hidden;

    &__text {
      .rich-text {
        h2 {
          font-size: $font-size-large;
          margin-bottom: 0;
        }

        & > :first-child {
          margin-top: 0;
        }
      }
    }

    &__items {
      @include list;
      display: flex;
      overflow: hidden;
    }

    &__image {
      margin-right: 10px;
      width: auto;
      max-width: none;
      height: 100px;
    }

    &__link {
      margin-bottom: 1em;
    }
  }

  @include media(">=tablet") {
    &-showcase {
      padding-left: 55px;

      &__items {
        padding-top: 4px;
      }

      &__text {
        margin-right: 320px;

        .rich-text {
          h2 {
            font-size: $font-size-huge;
          }
        }
      }

      &__image {
        height: 180px;
      }

      //&__link {
      //  appearance: none;
      //  outline: none;
      //  border: 0;
      //  background: $color-white;
      //  padding: 10px 30px;
      //  text-decoration: none;
      //  color: $color-black;
      //
      //  &:hover, &:focus {
      //    text-decoration: underline;
      //  }
      //}
    }
  }

  @include media(">=tablet-landscape") {
    &__top {
      display: flex;
      align-items: flex-start;
    }
    &__body {
      flex-basis: 50%;
    }
    &__tags.tags {
      margin-top: 1.5em;
      justify-content: flex-end;
    }

    &__body + &__tags {
      flex-basis: 50%;
    }

    &__footer {
      display: flex;

      & > * {
        flex: 1 1 50%;
      }
    }

    &-showcase {
      margin: 0;
      display: flex;
      background-color: $color-cod-gray;

      &__text {
        flex: 1 0 30%;
        margin: 10px 50px 0 0;
      }

      &__items {
        flex: 1 0 70%;
        position: relative;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 50%;
          background: linear-gradient(to right, transparent, $color-black 100%);
          content: "";
        }
      }
    }
  }
}
