.paragraph--intro {
  font-size: $font-size-larger;

  //.content > & {
  //  padding-left: 35px;
  //  padding-right: 35px;
  //}

  .rich-text > :first-child {
    margin-top: 0;
  }
}
