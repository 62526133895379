.selection-bar {
  $padding: 20px;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  //background: transparent;
  background: $color-yellow;
  color: $color-black;
  transform: translateY(calc(100% - #{$padding}));
  transition: transform 0.3s ease-in-out;

  &::after {
    margin: 0 !important;
  }

  @at-root [data-scrolled="up"] &, &:hover, &:focus, &:focus-within, &--show {
    transform: translateY(0);
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding: 0;
    //display: flex;
    //flex-flow: row
    //align-items: center;
    //justify-content: space-between;
  }

  &__title {
    margin-right: 20px;
    hyphens: manual;
  }

  &__inner {
    display: flex;
    justify-content: flex-end;
  }

  &__list {
    display: none;
  }

  &__item {
    display: flex;
    margin-right: 5px;

    a {
      display: block;
    }

    img {
      max-width: none;
      height: 50px;
    }

    &--has-more {
      position: relative;
      display: flex;
      align-items: center;

      img {
        opacity: 0.5;
      }

      b {
        position: absolute;
        text-align: center;
        width: 100%;
        pointer-events: none;
      }
    }
  }

  &__button {
    white-space: nowrap;
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      font-size: $font-size-larger;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
      }
    }
  }

  @include media(">=mobile-landscape") {
    &__content {
      flex-flow: column;
      transition: opacity 0.3s ease-in-out;
    }

    &__inner {
      margin-bottom: $padding;
    }

    &__list {
      @include list($margin: 0 auto 0 0);
      display: flex;
      justify-content: flex-end;
    }
    &__button {
      margin-left: 30px;
    }

    &--faded &__content {
      opacity: 0;
    }
  }

  @include media(">=tablet-landscape") {
    &__content {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
    }
    &__inner {
      margin-top:  $padding;
    }
  }
}
