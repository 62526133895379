/// Global element defaults

:root {
  --site-title: "netherlands film commission";
}

* {
  box-sizing: border-box;
  font-family: inherit;
}

html {
  background-color: $color-site-background;
  font: #{$font-size-normal-px}/#{$line-height-normal} $font-family-default;
  letter-spacing: 0.2px;
  color: $color-text;
}

body {
  min-height: 100vh;
}

body,
figure,
fieldset,
form {
  margin: 0;
}

a {
  color: $color-yellow;
  text-decoration: underline;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0 0 0.8em;
  //hyphens: auto;
  letter-spacing: 0.36px;
  font-weight: bold;
  line-height: 1.1;
}

h1, .h1 {
  font-size: $font-size-huge;
}
h2, .h2 {
  font-size: $font-size-large;
}
h3, .h3,
h4, .h4 {
  font-size: $font-size-larger;
}
h5, .h5,
h6, .h6 {
  font-size: $font-size-normal;
}

h1, .h1,
h2, .h2 {
  small {
    float: right;
    font-size: $font-size-larger;
  }
}

img {
  max-width: 100%;
}

fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

textarea {
  resize: none;
}
