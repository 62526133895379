.attachment-list {
  &.form__field {
    flex-flow: column;
    align-items: stretch;
  }

  &__list {
    @include list($margin: 10px 0 20px);
    flex: 1;
  }

  &__item {
    position: relative;
    margin-bottom: 2px;
    background-color: $color-mineshaft;
    font-size: $font-size-larger;
    font-weight: normal;
    color: rgba($color-white, 0.5);
  }

  &__label {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
  }

  &__delete-label {
    position: absolute;
    top: 50%;
    right: 10px;
    padding: 0.2em 0.5em;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__delete-input:checked + &__label {
    opacity: 0.5;
    text-decoration: line-through;
  }

  &__image {
    margin-right: 12px;
  }

  //&__file-input[value] + &__label > &__file-display::before {
  //  content: "wooop"
  //}

  &__buttons {
    margin-bottom: 2rem;

    .button {
      margin-right: 10px;
    }
  }
}
