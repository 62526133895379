.expandable {
  overflow: hidden;

  @keyframes expand {
    0% {max-height: 0;}
    95% {max-height: 125vh}
    100% {max-height: none}
  }
  @keyframes collapse {
    0% {max-height: 100vh}
    100% {max-height: 0}
  }

  &[data-expanding] {
    animation: expand 0.5s ease-in-out;
  }
  &[data-collapsing] {
    animation: collapse 0.5s ease-in-out;
  }
  &--collapsed {
    max-height: 0;
  }
  &__expand {
    position: relative;
    appearance: none;
    margin-top: 1em;
    outline: none;
    border: 0;
    background: none;
    padding: 0 0 0 1em;
    font: inherit;
    font-size: $font-size-normal;
    color: $color-yellow;
    cursor: pointer;
  }
}
