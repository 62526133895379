@keyframes message-show {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes message-hide {
  from { opacity: 1; }
  to { opacity: 0; }
}

.messages {
  position: absolute;
  top: 100%;
  left: 20px;
  right: 20px;
  @include list($margin: 25px 0 0);

  &__message {
    margin-bottom: 20px;
    max-height: 120px;
    overflow: hidden;
    background: $color-yellow;
    padding: 20px 30px;
    animation: message-show 0.3s ease-in-out 1s both;
    color: $color-black;
    display: flex;

    &--hidden {
      animation-name: message-hide;
      animation-delay: 0s;
    }
  }

  &__close {
    padding: 0;
    margin-left: auto;
    border: 0;
    background: transparent;
    font: inherit;
    text-transform: uppercase;
    font-weight: normal;
    cursor: pointer;
  }

  @include media(">=tablet-landscape") {
    left: 55px;
    right: 55px;
  }
}
