/// Rich text, wrapper put around rich text by Wagtail

/// elements available by default in the Draftail editor:
.rich-text {
  h2,
  h3,
  h4 {
  }

  p,
  ol,
  ul,
  hr,
  iframe {
  }

  b,
  i {
  }

  a {
    // download links:
    &[href^="#{$documents-root}"] {
    }
  }

  //.content > & {
  //  padding-left: 35px;
  //  padding-right: 35px;
  //}
}

/// Inserted images receive this class and one of the 'modifier' classes:
.richtext-image {
  max-width: 100%;
  height: auto;

  &.full-width {
    display: block;
  }

  &.left {
    float: left;
    margin-right: 20px;
  }

  &.right {
    float: right;
    margin-left: 20px;
  }
}

/// inserted YouTube video's e.g. receive this with an inline style padding for the aspect ratio:
.responsive-object {
  position: relative;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
