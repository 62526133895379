.tag-search {
  position: relative;
  margin: 0;
  background: $color-cod-gray;
  color: $color-white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0 10px;
  font-size: $font-size-normal;

  &::before {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    min-height: 35px;
    width: 18px;
    background: 0 center / 18px svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd" stroke="#FFF"><circle cx="9" cy="9" r="7.8" stroke-width="2.5"/><path stroke-linecap="square" stroke-width="3" d="M15.2 15.2l2.4 2.4"/></g></svg>') no-repeat;
    content: "";
  }

  &__list {
    @include list($padding: 10px 20px 0 0);
    max-width: calc(100% - 20px);
    display: flex;
    align-items: center;
  }

  &__row {
    position: relative;
    margin: 0;
    flex: 1 1 320px;
    display: flex;
    align-items: center;
    padding: 15px 0;
    max-width: calc(100% - 135px);
    //background: green;
  }

  select {
    @include accessible-hidden;
  }

  &__input {
    position: relative;
    //left: 12px;
    z-index: 1;
    outline: 0;
    border: 0;
    padding: 0;
    width: 100%;
    background: inherit;
    font: inherit;
    color: inherit;
  }

  &__label {
    position: absolute;
    left: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &__input:focus ~ &__label {
    opacity: 0.5;
  }

  &__input:valid ~ &__label {
    display: none;
  }

  &__submit {
    @include accessible-hidden;
  }

  &__clear {
    position: absolute;
    //top: 50%;
    right: 0;
    bottom: 0;
    padding: 15px 10px;
    //transform: translateY(-50%);
    color: $color-white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @at-root .js & select {
    height: 1.5em;
    display: none;
  }

  @include media(">=mobile-landscape") {
    font-size: $font-size-larger;

    &__row {
      max-width: calc(100% - 150px);
    }
  }

  @include media(">=tablet") {
    padding-right: 150px; // about the width of "remove all filters"

    &__row {
      flex-basis: 190px; // about the width of "add additional filters"
    }
    &__clear {
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}
