@use "sass:math";
/// Global variables

/// Paths:
$static-root: "/static/filmcommission/";
$documents-root: "/documents/";

/// Explicit measurements
$max-outer-width: 1440px;
// when the main menu toggles from drop-down to inline items:
$menu-break: 1024px;

/// Breakpoints:
$breakpoints: (
  "mobile-landscape": 480px,
  "tablet": 768px,
  "tablet-landscape": 1024px,
  "menu-break": $menu-break,
  "desktop": 1280px,
  "large": 1440px,
  "content-centered": $max-outer-width + 110px,
  "huge": 1920px
);

/// Colors:
$color-black: #000000;
$color-cod-gray: #111111;
$color-mineshaft: #2a2929;
$color-tundora: #424141;
$color-emperor: #545454;
$color-gray: #929292;
$color-dove-gray: $color-gray; // #727272;
$color-yellow: #FFF000;
$color-white: #ffffff;

$color-facebook-blue: #4266b2;
$color-google-plus-red: #de4a38;
$color-pinterest-red: #bd091d;
$color-tumblr-blue: #2b4563;
$color-twitter-blue: #21a9e3;

/// Applied colors:
$color-text: $color-white;

$color-site-background: $color-cod-gray;

$color-primary-button-background: $color-black;
$color-primary-button-text: $color-white;

/// Font sizes:
$font-size-base: 16;

$font-size-tiny: math.div(12, $font-size-base) * 1rem;
$font-size-small: math.div(14, $font-size-base) * 1rem;
$font-size-normal: 1rem;
$font-size-normal-px: $font-size-base * 1px;
$font-size-larger: math.div(20, $font-size-base) * 1rem;
$font-size-large: math.div(24, $font-size-base) * 1rem;
$font-size-huge: math.div(36, $font-size-base) * 1rem;

/// Applied font sizes:
$font-size-h1: $font-size-huge;
$font-size-h2: $font-size-large;
$font-size-h3: $font-size-larger;
$font-size-h4: $font-size-normal;

$headline-font-size-mobile: 40;
$headline-font-size-portrait: 60;
$headline-font-size-landscape: 80;

/// Font families:
$font-family-default: "Neue Haas Grotesk", Arial, sans-serif;
$font-family-icons: "some-icon-font", $font-family-default;

/// Line heights:
$line-height-narrow: 1.2;
$line-height-normal: 1.5;

/// Easing functions
$ease-out-elastic: cubic-bezier(0.185, 1.65, 0, 0.815);

/// Default Jacket context if not provided
$jacket: critical, non-critical !default;
