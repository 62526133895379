.profile-list {
  &__heading {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
  &__results {
    display: flex;
    align-items: center;
  }
  &__heading {
    margin-left: 30px;
  }
  &__field {
    background: $color-black;
    border-radius: 5px;
    padding: 10px 20px;

    button {
      outline: none;
      appearance: none;
      border: 0;
      background: none;
      font: inherit;
      color: inherit;
      cursor: pointer;
      font-weight: bold;
    }

    select {
      margin-left: 10px;
    }

    label {
      display: flex;
    }

    @at-root .js & button {
      @include accessible-hidden;
    }
  }
  &__item {
    margin-left: 30px;
  }

  &--inline {
    .profile {
      padding-left: 0;
    }
  }

  @include media(">=tablet-landscape") {
    &__body,
    &__heading {
      margin-left: 35px;
    }
    &__item {
      margin-left: 0;
    }
  }
}
