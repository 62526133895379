// Required Core Stylesheet
//@import "node_modules/@splidejs/splide/src/sass/core/splide-core";
@import "node_modules/@splidejs/splide/dist/css/splide-core.min";

.splide {
  margin: 0 -20px;

  &__arrows {
    position: absolute;
    left: 50%;
    z-index: 1;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateX(-50vw);
    pointer-events: none;
  }

  &__arrow {
    pointer-events: all;
    position: relative;
    z-index: 1;
    border: 0;
    padding: 20px;
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, .25));
    width: 100px;
    text-align: right;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    svg {
      width: 20px;
    }

    path {
      fill: $color-yellow;
      transition: fill 0.3s ease-in-out;
    }

    &--prev {
      transform: rotateY(180deg);
    }

    &:hover {
      path {
        fill: $color-white;
      }
    }

    &:disabled {
      opacity: 0;
    }
  }

  &__track {
    overflow: visible;
  }

  &__slide {
    margin-right: 10px;
  }

  .content__inner {
    padding-left: 20px;
    margin-right: 10px;
  }
}
