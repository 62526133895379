@use "sass:math";

$col-gutter: 15px;
$max-cols: 12;
$sm-cols: ();
$md-cols: 5,7;
$lg-cols: 4,8;
$lg-push: 4,8;

.row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -$col-gutter;
  margin-right: -$col-gutter;
}

.col {
  padding-left: $col-gutter;
  padding-right: $col-gutter;
  width: 100%;

  @each $col in $sm-cols {
    &-sm-#{$col} {
      width: math.div(100%, math.div($max-cols, $col));
    }
  }

  @if length($md-cols) > 0 {
    @include media(">=tablet") {
      @each $col in $md-cols {
        &-md-#{$col} {
          width: math.div(100%, math.div($max-cols, $col));
        }
      }
    }
  }

  @if length($lg-cols) > 0 or length($lg-push) > 0 {
    @include media(">=tablet-landscape") {
      @each $col in $lg-cols {
        &-lg-#{$col} {
          width: math.div(100%, math.div($max-cols, $col));
        }
      }
      @each $col in $lg-push {
        &-lg-push-#{$col} {
          margin-left: math.div(100%, math.div($max-cols, $col));
        }
      }
    }
  }
}

.push {
  @if length($lg-push) > 0 {
    @include media(">=tablet-landscape") {
      @each $col in $lg-push {
        &-lg-#{$col} {
          margin-left: math.div(100%, math.div($max-cols, $col));
        }
      }
    }
  }
}
