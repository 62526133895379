/// Site header
@use "sass:math";

.site-header {
  position: relative;
  z-index: 4;
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: bold;

  &__title {
    position: relative;
    z-index: 1;
    margin: 0;
    max-width: 70vw;
    font: inherit;
    font-size: $font-size-larger;
    text-transform: lowercase;
    hyphens: none; // ensures commission isn't broken into com- mission
    transition: color 0.2s ease-in-out;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $color-yellow;
      }
    }
  }

  //@at-root .page--modal-title .site-header {
  //  position: static;
  //  z-index: auto;
  //}
  //
  //@at-root .page--modal-title &__title {
  //  z-index: 5;
  //  color: $color-black;
  //}

  &--menu &__title {
    color: $color-black;
  }

  &__menu-toggle {
    order: 1;
    position: relative;
    //margin: 0.4em 0 0 0;
    overflow: hidden;
    padding-left: 30px;
    width: 30px;
    white-space: nowrap;
    line-height: 17px;
    text-decoration: none;
    color: inherit;

    &::before, &::after {
      position: absolute;
      right: 0;
      width: 20px;
    }

    &--nav {
      &::before, &::after {
        border: solid currentColor;
        transition: 0.2s ease-in-out;
        transition-property: transform, width;
        content: "";
      }

      // top and bottom:
      &::before {
        top: 0;
        bottom: 0;
        border-width: 3px 0;
        transform: translateX(4px) skewX(-10deg);
      }

      // middle:
      &::after {
        top: 50%;
        margin-top: -2px;
        transform: translateX(0);
        border-width: 3px 0 0;
      }

      &:hover, &:focus {
        outline: none;

        &::before {
          transform: translateX(0) skewX(0);
        }

        &::after {
          width: 20px;
        }
      }
    }

    &--search {
      margin-left: 35px;

      &::after {
        top: 0;
        width: 1.9em;
        height: 1.2em;
        background: 0 0 / 18px svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd" stroke="#FFF"><circle cx="9" cy="9" r="7.8" stroke-width="2.5"/><path stroke-linecap="square" stroke-width="3" d="M15.2 15.2l2.4 2.4"/></g></svg>') no-repeat;
        content: "";
      }
    }
  }

  &__panel {
    &:focus ~ .site-header__close,
    &:focus-within ~ .site-header__close,
    &:target ~ .site-header__close {
      animation: appear 0.3s ease-in-out both 0.5s;
      display: block;
    }
  }

  &__close {
    display: none;
  }

  &__user-menu {
    position: relative;
    top: -40px;
    right: -40px;
  }
  &__user-name {
    font-weight: normal;
    font-size: $font-size-larger;

    a {
      font-weight: bold;
      text-decoration: none;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
}

.user-menu {
  a {
    font-weight: bold;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  &__toggle {
    border-radius: 6px 6px 0 0;
    padding: 40px;
    font-weight: normal;
    font-size: $font-size-larger;
  }
  &__collapsible {
    border-radius: 0 0 6px 6px;
    padding: 0 40px 40px;
  }
  &[open] a {
    color: $color-black;
  }
  &__section-title {
    margin: 0;
    font-weight: normal;
    font-size: $font-size-small;
  }
  &__list {
    @include list;

    &-item {
      margin: 1em 0 0;

      &--inactive {
        opacity: 0.5;
      }
    }
  }
}

.site-menu { // also .site-header__panel
  &__list {
    @include list;
    display: flex;
  }

  &__item {
    padding: 0 20px;
  }

  &__link {
    text-decoration: none;
    color: $color-black;
    text-transform: lowercase;

    &:hover {
      text-decoration: underline;
    }
  }

  &:focus &__list,
  &:focus-within &__list,
  &:target &__list {
    //bottom: 0;
    max-height: 100vh;
    //opacity: 1;
  }

  &--tablet {
    display: none;
  }
  @include media(">=tablet-landscape") {
    &--tablet {
      display: block;
    }
  }
}

.site-search { // also .site-header__panel
  position: fixed;
  top: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  outline: none;
  overflow: hidden;
  background: $color-mineshaft;
  padding: 0 25px;
  display: flex;
  flex-flow: column;
  //transition: height 0.2s ease-in-out;
  transition: 0.3s ease-in-out;
  transition-property: top, height;

  &:focus,
  &:focus-within,
  &:target {
    //bottom: 0;
    //height: 100vh;
    //opacity: 1;
    top: 0;
    overflow: auto;
  }

  &:focus ~ .site-header__close,
  &:focus-within ~ .site-header__close,
  &:target ~ .site-header__close {
    animation: appear 0.3s ease-in-out both 0.5s;
    color: $color-white;
  }

  &__title {
    margin-top: 50px;
  }

  &__button {
    @include accessible-hidden;
  }

  &__row {
    margin: 0 0 2rem;
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    background: $color-black;
  }

  &__label {
    flex: 0 0 20px;
    padding: 20px 30px;
    overflow: hidden;
    text-indent: 30px;
    background: center / 20px svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd" stroke="#FFF"><circle cx="9" cy="9" r="7.8" stroke-width="2.5"/><path stroke-linecap="square" stroke-width="3" d="M15.2 15.2l2.4 2.4"/></g></svg>') no-repeat;
    white-space: nowrap;
  }

  &__input {
    appearance: none;
    outline: none;
    border: 0;
    padding: 20px 20px 20px 0;
    width: 100%;
    background: inherit;
    font-size: $font-size-larger;
    color: $color-white;
    transition: color 0.3s ease-in-out;

    &::placeholder {
      color: inherit;
      opacity: 1;
    }
    &:focus::placeholder {
      color: rgba($color-white, 0.5);
    }

    &:valid {
      font-weight: bold;
      color: $color-yellow;

      + .site-search__label {
        background-image: svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd" stroke="#FFF000"><circle cx="9" cy="9" r="7.8" stroke-width="2.5"/><path stroke-linecap="square" stroke-width="3" d="M15.2 15.2l2.4 2.4"/></g></svg>')
      }
    }
  }

  &__section {
    font-size: $font-size-larger;
    text-transform: lowercase;
    color: $color-dove-gray;
  }

  &__suggestions {
    li {
      margin-bottom: 0.5em;
    }
    a {
      color: $color-white;
      font-weight: normal;
    }
  }

  &__results-container {
    p {
      margin: 0;
    }
  }

  &__results {
    @include list($margin: 0 0 50px);

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__result {
    position: relative;
    border: solid $color-tundora;
    border-width: 2px 0 0;
    padding: 1em 0;
    display: flex;
    justify-content: flex-start;

    &:last-child {
      border-bottom-width: 2px;
    }

    &--images {
      flex-flow: column;
    }

    a {
      display: block;
      font-weight: bold;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
      }
    }
  }

  &__key {
    min-width: 85px;

    a {
      color: $color-white;
    }
  }

  &__value {
    margin: 0;
  }
}

.overlay-close {
  position: fixed;
  right: 25px;
  z-index: 4;
  outline: none;
  border: 0;
  background: transparent;
  padding-right: 1.1em;
  font-size: $font-size-larger;
  font-weight: bold;
  text-decoration: none;
  color: $color-black;
  cursor: pointer;

  &--static {
    position: absolute;
  }

  h1 + & {
    transform: translateY(-225%);
  }

  &--inverted {
    color: $color-white;
  }

  &::before, &::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0.7em;
    border-bottom: 3px solid currentColor;
    transform: rotate(-45deg);
    content: "";
  }

  &::after {
    transform: rotate(45deg);
  }
}

@include media(">=tablet") {
  .site-header {
    max-width: $max-outer-width + 110px;
    padding-left: 55px;
    padding-right: 55px;
  }

  .overlay-close {
    right: 55px;
  }
}

@include media("<#{$menu-break}") {
  .site-header {
    &__menu-toggle {
      &--search {
        margin-left: auto;
      }
    }
  }
  .site-menu {
    &__list {
      position: fixed;
      overflow: auto;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      max-height: 0;
      z-index: 4;
      flex-flow: column;
      background: $color-yellow;
      font-size: rem(40);
      transition: max-height 0.2s ease-in-out;
    }
    &__item {
      margin-bottom: 50px;

      &:first-child {
        padding-top: 30px;
      }
    }
  }
}

@include media("<menu-break", "height>=560px") {
  // when mobile menu but height is larger than 560 justify the items at the end.
  .site-menu__list {
    justify-content: flex-end;
  }
}

@include media("<menu-break", "height>=660px") {
  // when mobile menu but height is larger than 560 justify the items at the end.
  .site-menu__item {
    &:last-child {
      padding-bottom: 100px;
    }
  }
}

@include media("<mobile-landscape") {
  .site-menu {
    &__list {
      font-size: rem(30);
    }
    &__item {
      margin-bottom: 40px;
    }
  }
}

@include media(">=#{$menu-break}") {
  .site-header {
    justify-content: flex-end;

    &__title {
      margin-right: auto;
      white-space: nowrap;
    }

    &__menu-toggle {
      margin-top: 0.45em;
      width: auto;
      padding: 0;

      &::after {
        position: static;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }

      &--nav {
        @include accessible-hidden;
      }
    }
  }


  .site-menu {
    $item-spacing: 30px;
    margin: 0.25em 0 0 $item-spacing*3;
    outline: 0;
    //flex: 0;

    &__list {
      justify-content: flex-end;
    }

    &__item {
      margin-left: $item-spacing;
      padding: 0;

      &--home {
        @include accessible-hidden;
      }
      &:nth-child(2) {
        margin-left: 0;
      }
    }

    &__link {
      color: $color-white;

      &--active, &:hover {
        color: $color-yellow;
      }
      &--active {
        text-decoration: underline;
      }
    }

    &:focus ~ .site-header__close,
    &:focus-within ~ .site-header__close,
    &:target ~ .site-header__close {
      display: none;
    }
  }

  .site-search {
    &__result {
      &--images {
        flex-flow: row;
      }
      &--page {
        flex-flow: column;

        p {
          font-weight: normal;
        }
      }
    }
    &__key {
      min-width: 225px;
    }
  }
}

@include media(">=#{$menu-break}", "<=1075px") {
  .site-menu {
    margin-right: 40px;
    &__list {
      max-width: 420px;
    }
    &__item {
      margin-left: 40px;
    }
  }
}

@include media(">=desktop") {
  .site-header {
    font-size: $font-size-larger;
    &__menu-toggle {
      margin-top: 0.2em;
    }
  }
  .site-menu {
    margin-top: 0;
  }
}

@include media(">=#{$max-outer-width + 70px}") { // 1440 + 2x35px
  .overlay-close {
    right: 50%;
    transform: translateX(#{math.div($max-outer-width, 2)});
  }
}
