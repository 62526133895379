.home-page[data-intro] {
  // each line appears with a delay of:
  $line-appear-delay: 0.3s; // multiplied by the index of each line
  $line-appear-duration: 0.2s;
  // lines vanish after:
  $line-vanish-delay: 1.5s;
  $line-vanish-duration: 0.3s;
  // overlay fades after:
  $overlay-fade-delay: 2s;
  $overlay-fade-duration: 0.6s;
  // headline resets after:
  $headline-reset-delay: 2s;
  $headline-reset-duration: 0.1s;
  // lines re-appear after:
  $line-reappear-delay: 3s;
  $line-reappear-duration: 0.3s;
  // locations appear after:
  $locations-appear-delay: 3.5s;
  $locations-appear-duration: 0.3s;
  // highlights appear after:
  $highlights-appear-delay: 4s;
  $highlights-appear-duration: 0.5s;
  // video toggle apper after:
  $video-toggle-appear-delay: 5s;
  $video-toggle-appear-duration: 0.3s;

  $z: 5;

  // We start with the main::before pseudo as overlay with only "Take"

  // These keyframes are just to apply a reset state, they don't need to
  // visually animate. We need a different animation for each breakpoint because
  // animations are unable to "inherit" from a parent element.
  @keyframes reset-font-size {
    to {
      font-size: $headline-font-size-mobile * 1px;
    }
  }
  @keyframes reset-font-size-portrait {
    to {
      font-size: $headline-font-size-portrait * 1px;
    }
  }
  @keyframes reset-font-size-landscape {
    to {
      font-size: $headline-font-size-landscape * 1px;
    }
  }

  // Apply the large "fluid" font-size for the splash screen:
  // This is the only change outside of an animation so without animation support
  // all text could be huge...
  .headline__prefix, .headline__line {
    @include fluid-value(320px, 1440px, 100px, 190px);
  }

  // each line appears and then vanishes at some point, resets its font-size and re-appears:
  $line-vanish-reset-reappear-animation:
    appear $line-appear-duration ease-in both,
    vanish $line-vanish-duration ease-in forwards $line-vanish-delay,
    reset-font-size $headline-reset-duration forwards $headline-reset-delay,
    appear $line-reappear-duration ease-in forwards $line-reappear-delay;

  // The combined vanish/reset/reappear delays as these never change:
  $line-vanish-reset-reappear-delays:
    $line-vanish-delay, $headline-reset-delay, $line-reappear-delay;

  // the "Take" prefix is visible right away and all lines then do the vanish, reset
  // and re-appear animations.
  .headline__prefix, .headline__line {
    animation: $line-vanish-reset-reappear-animation;
  }
  // Each line appears later (with an increasing delay)
  // and then also vanishes, resets and re-appears
  .headline__line {
    opacity: 0;

    &--1 {
      animation-delay: $line-appear-delay * 1, $line-vanish-reset-reappear-delays;
    }
    &--2 {
      animation-delay: $line-appear-delay * 2, $line-vanish-reset-reappear-delays;
    }
    &--3 {
      animation-delay: $line-appear-delay * 3, $line-vanish-reset-reappear-delays;
    }
  }

  // Then the headline resets to it's original position

  @keyframes headline-resets {
    from {
      position: fixed;
      margin: 0;
      top: 50vh;
      z-index: $z;
      transform: translate(-50%, -50%);
    }
    to {
      margin: -60px 0 0 0;
      position: absolute;
      top: auto;
      transform: translate(-50%, -100%);
      opacity: 1;
      z-index: auto;
    }
  }
  .headline {
    animation: headline-resets $headline-reset-duration linear both $headline-reset-delay;
  }

  // Then the overlay fades

  @keyframes overlay-fade {
    0% {
      right: 0;
      z-index: $z;
      background: $color-mineshaft;
      opacity: 1;
      bottom: 0;
    }
    99% {
      opacity: 0;
      bottom: 0;
      pointer-events: all;
    }
    100% {
      opacity: 0;
      bottom: 100%;
      pointer-events: none;
    }
  }
  main::before {
    position: fixed;
    top: 0;
    left: 0;
    animation: overlay-fade $overlay-fade-duration ease-in-out both $overlay-fade-delay;
    content: "";
  }

  .home-page__video-toggle {
    animation: appear $video-toggle-appear-duration ease-out both $video-toggle-appear-delay;
  }

  @include media(">=tablet") {
    // adjust the reset-font-size animation in this breakpoint:
    .headline__prefix, .headline__line {
      animation-name: appear, vanish, reset-font-size-portrait, appear;
    }

    @keyframes move-up-appear {
      from {
        opacity: 0;
        transform: translateY(10%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    .home-page__top-content {
      animation: move-up-appear $locations-appear-duration ease-out both $locations-appear-delay;
    }

    // Then the highlights appear:
    // (portrait+ only)
    .home-page__highlights {
      //.teaser > * {
        animation: move-up-appear $highlights-appear-duration ease-out both $highlights-appear-delay;
      //}
    }
  }

  @include media(">=tablet-landscape") {
    // adjust the reset-font-size animation in this breakpoint:
    .headline__prefix, .headline__line {
      animation-name: appear, vanish, reset-font-size-landscape, appear;
    }

    @keyframes headline-resets-landscape {
      from {
        position: fixed;
        left: 0;
        margin: 0;
        top: 50vh;
        z-index: $z;
        transform: translate(0, -50%);
      }
      to {
        margin: -260px 0 0 0;
        position: absolute;
        top: auto;
        left: 50%;
        transform: translate(-50%, -100%);
        opacity: 1;
        z-index: auto;
      }
    }
    .headline {
      animation-name: headline-resets-landscape;
    }
  }
}
