.button-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  .button:not(:first-child) {
    margin-left: 30px;
  }
}
