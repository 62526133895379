/// Generic classes

.hidden {
  @include accessible-hidden;
}

.list {
  @include list;
}

.a {
  &--inverted {
    color: $color-white;
  }
}

.offset {
  &--top {
    &-2 {
      margin-top: 2rem;
    }
    &-3 {
      margin-top: 3rem;
    }
    &-4 {
      margin-top: 3rem;
    }
  }

  &--bottom {
    &-2 {
      margin-bottom: 2rem;
    }
    &-3 {
      margin-bottom: 3rem;
    }
  }
}

.floats {
  &--right {
    float: right;
    margin-left: 1rem;
  }
}

.heading-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
