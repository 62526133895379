@keyframes fly-in {
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
}
@keyframes fly-out {
  from { transform: translateY(0); }
  to { transform: translateY(100%); }
}

$modal-back: rgba(0, 0, 0, 0.5);

@keyframes modal-fade-in {
  from { background-color: transparent; }
  to { background-color: $modal-back; }
}
@keyframes modal-fade-out {
  from { background-color: $modal-back; }
  to { background-color: transparent; }
}

@keyframes modal-appear {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes modal-fade {
  from { opacity: 1; }
  to { opacity: 0; }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  justify-content: center;

  &--showing {
    animation: fly-in 0.5s ease-in-out;
  }
  &--closing {
    animation: fly-out 0.5s ease-in-out;
  }

  &__content {
    flex: 1;
    overflow-y: auto;

    & > .content {
      padding-top: 0;

      > h1:first-child {
        margin: 2em 100px 2em 0;
        font-size: $font-size-large;
      }
    }
  }

  &--full &__content {
    padding: 0;
  }

  &__close-button {
    position: absolute;
    top: 50px;
    color: $color-white;

    @at-root .page--modal-title &,
    &--inverted {
      color: $color-black;
    }
  }

  &--centered {
    align-items: center;
    justify-content: center;
    background: $modal-back;
  }
  &--centered.modal--showing {
    animation-name: modal-fade-in;

    .modal__content {
      animation: modal-appear 0.5s ease-in-out 2s;
    }
  }
  &--centered.modal--closing {
    animation-name: modal-fade-out;
    animation-delay: 1s;

    .modal__content {
      animation: modal-fade 0.5s ease-in-out;
    }
  }
  &--centered &__content {
    margin: auto 30px;
    flex: 0 1 520px;
    padding: 30px;
  }

  // specific style for a 'form' modal:
  &__content--form,
  &__content--form .site-container {
    background: $color-black;
  }

  & &__content--form {
    max-width: 940px;
    flex: 1 720px;
  }

  &__content--form &__close-button {
    color: $color-white;
  }

  .hidden-in-modal {
    @include accessible-hidden;
  }

  @include media(">=tablet") {
    &__content {
      position: relative; // needed for the close button to scroll

      & > .content {
        padding-top: 0;

        > h1:first-child {
          margin: 1.25em 100px 1.25em 0;
          font-size: $font-size-huge;
        }
      }
    }
  }
}

body.scroll-locked {
  overflow: hidden;
}
