.text {
  &--larger {
    font-size: $font-size-larger;
  }
  &--large {
    font-size: $font-size-large;
  }

  &--indent-75 {
    padding-left: 75px;
  }
}
