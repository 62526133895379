.contact-box {  // details
  min-width: 300px;
  background: $color-black;
  padding: 30px 45px;

  &__title {  // summary
    margin: 0;
    outline: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    cursor: pointer;

    &::after {
      width: 10px;
      height: 10px;
      border: solid $color-white;
      border-width: 2px 0 0 2px;
      transition: transform 0.3s ease-in-out;
      content: "";
    }
  }

  &[open] &__title::after {
    transform: rotate(225deg);
  }
  &__title::after, &[data-closing] &__title::after {
    transform: rotate(45deg);
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  &[open] > &__content,
  &[data-opening] > &__content {
    max-height: 300px;
  }
  &[data-closing] > &__content {
    max-height: 0;
  }

  .filters__container & {
    margin-block-start: auto;
  }

  @include media("<tablet") {
    // when a .actions-list is position: fixed at the bottom of the screen.
    &--with-actions-list {
      // line-height + 2 x 15px button padding.
      margin-bottom: calc(1.5rem + 30px);
    }
  }

  @include media(">=tablet") {
    position: fixed;
    //left: 60px;
    bottom: 0;
  }
}
