.page--home {
  .site-header {
    //position: absolute;
    //top: 0;
    //left: 50%;
    //width: 100%;
    //z-index: 1;
    //transform: translateX(-50%);
    //max-width: 100%;
    height: 100px;
    margin-bottom: -100px;
  }

  main {
    margin-top: 0;
    background-color: $color-black;
  }
}

.home-page {
  main {
    display: block;
  }

  &__video-container {
    overflow: hidden;
    position: relative;
    min-height: 280px;
    height: calc(100vh - 400px);
  }
  &__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__headline {
    position: absolute;
    left: 50%;
    margin-top: -60px;
    padding-left: 20px;
    transform: translate(-50%, -100%);
  }

  &__video-toggle-container {
    position: relative;
    top: -50px;
    padding: 0 20px;
  }

  &__video-toggle {
    //margin-left: 20px;
    outline: none;
    border: 0;
    background: none;
    padding: 0;
    appearance: none;
    font: inherit;
    text-decoration: underline;
    color: $color-yellow;
    //transition: opacity 0.5s ease-in-out;
    cursor: pointer;

    &:focus, &:hover {
      text-decoration: none;
    }

    &::before {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
      background: 0 0 / 1em svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20"><g fill="#FFF000" fill-rule="nonzero"><path d="M14 13.2v1.4c2.2 0 4-1.8 4-4.6s-1.8-4.7-4-4.7v1.5c1.4 0 2.6 1.4 2.6 3.2 0 1.8-1.2 3.2-2.6 3.2z"/><path d="M14 16.8v1.4c4.9 0 8-3.3 8-8.2 0-4.9-3.1-8-8-8v1.4c3.6 0 6.5 2.3 6.5 6.6s-2.9 6.8-6.5 6.8zM12 20V0L4.1 5.4H0v9.2h4.1L12 20zM1.5 7h3c.1 0 .4-.1.5-.3L10.5 3v14.2L5 13.3a.8.8 0 00-.6-.3H1.5V7z"/></g></svg>') no-repeat;
      width: 1em;
      height: 1em;
      content: "";
    }

    &--toggled {
      //opacity: 0.3;
    }
  }

  .site-container--top-bar {
    //$overlap: 60px;
    //margin-bottom: -$overlap;
    background-color: $color-cod-gray;
    overflow: visible;

    &::after {
      display: none;
    }
  }

  &__top-content {
    position: relative;
    margin: 0 auto 20px;
    padding-top: 30px;
    background-color: $color-cod-gray;
  }

  &__highlights {
    .contact-card {
      margin: 0;
    }
  }

  &__content {
    padding: 0;
  }
}

@include media(">=tablet") {
  .home-page {
    &__video-container {
      height: 60vh;//calc(100vh - 430px);
      min-height: 320px;
    }

    &__video-toggle-container {
      padding-left: 20px; // 35 from .content--wide + 20 = 55
    }

    &__headline {
      padding-left: 50px;
    }

    .site-container--top-bar {
      background-color: transparent;
      padding: 0;

      & + .site-container {
        padding-top: 50px;
      }
    }

    &__top-content {
      margin-bottom: 0;
    }

    &__highlights {
      position: absolute;
      right: 0;
      bottom: -40px;
      max-width: 300px;
    }

    &__content {
      display: flex;
      flex-flow: column;
    }
    &__column {
      max-width: 80%;

      &--updates {
        //align-self: flex-end;
      }
    }
    &__column-title {
      font-size: $font-size-huge;
    }
  }
}

@include media(">=tablet-landscape") {
  .home-page {
    $pull-content-in: 70px;

    &__video-container {
      //margin-bottom: -257px;
      height: calc(100vh - #{$pull-content-in});
      min-height: 700px;
    }
    &__video-toggle-container {
      transform: translateY(-280px);
    }
    &__headline {
      margin-top: -320px;
    }
    .site-container--top-bar {
      position: absolute;
      width: 100%;
      transform: translateY(calc(-100% + #{$pull-content-in}));

      & + .site-container {
        padding-top: 130px;
      }
    }
    &__highlights {
      max-width: 390px;
    }
    &__content {
      flex-flow: row;
      justify-content: space-between;
    }
    &__column {
      flex: 0 0 45%;
    }
  }
}

@include media(">=large") {
  .home-page {
    &__highlights {
      right: 20px;
    }
  }
}

@include media(">=content-centered") {
  .home-page {
    &__video-toggle-container {
      padding-left: 0;
    }
  }
}

@include media(">=huge") {
  .home-page {
    &__video-container {
      max-height: 95vh;
    }
    &__video-toggle-container {
      padding-left: 0;
    }
  }
}
