/// Fluid value mixin

@function fluid-value-calc($min-vw, $max-vw, $min-value, $max-value) {
  @return calc(
    #{$min-value} + #{strip-unit($max-value - $min-value)} *
      ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
  );
}

/// Makes the given property scale fluently between two values between two viewport width breakpoints.
///
/// Note: The unit of *all* the given arguments must be the same!
///
/// @param {Length} $min-vw - Viewport width to start scaling.
/// @param {Length} $max-vw - Viewport width to end scaling.
/// @param {Length} $min-value - The value to start with.
/// @param {Length} $max-value - The value to end with.
/// @param {Length} $props [(font-size,)] - The properties to scale. By default only `font-size` is calculated.
///
/// @example scss - Usage
///     .title {
///         @include fluid-value(1024px, 1440px, 32px, 64px);
///     }
///
/// @example css - CSS output
///     .title {
///         font-size: 32px;
///     }
///
///     @media screen and (min-width: 1024px) {
///         .title {
///             font-size: calc(32px + 32 * ((100vw - 1024px) / 416));
///         }
///     }
///
///     @media screen and (min-width: 1440px) {
///         .title {
///             font-size: 64px;
///         }
///     }
@mixin fluid-value($min-vw, $max-vw, $min-value, $max-value, $props: font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-value);
  $u4: unit($max-value);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    @each $prop in $props {
      #{$prop}: $min-value;
    }
    @media screen and (min-width: $min-vw) {
      @each $prop in $props {
        //#{$prop}: calc(
        //  #{$min-value} +
        //    #{strip-unit($max-value - $min-value)} *
        //    ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        //);
        #{$prop}: fluid-value-calc($min-vw, $max-vw, $min-value, $max-value)
      }
    }

    @media screen and (min-width: $max-vw) {
      @each $prop in $props {
        #{$prop}: $max-value;
      }
    }
  } @else {
    @warn "The unit of $min-vw (#{$u1}) must the the same as all other arguments (#{$u2}, #{$u3}, #{$u4})";
  }
}
