.dl-table { // <div>
  color: rgba($color-white, 0.5);

  &__row { // <dl>
    margin: 0 0 5px;
    padding: 0;
    display: flex;
  }
  &__col { // <div>
    flex: 1;
    display: flex;
    flex-flow: column;

    &--actions {
      //grid-area: row-end;
    }
  }

  // we hide headings except in the first row:
  &__th { // <dt>
    display: none;
  }
  // show headings in the first row
  &__row:first-child &__th {
    display: block;
    padding: 10px 20px;
    font-weight: normal;
    font-size: $font-size-small;
  }

  &__td { // <dd>
    margin: 0;
    background: $color-tundora;
    display: flex;
    flex: 1;
  }

  &__col--actions &__td {
    justify-content: flex-end;
  }

  a {
    text-decoration: none;
    color: $color-white;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media("<tablet") {
    &__row {
      position: relative;
      flex-flow: column;

      &::after {
        display: block;
        height: 20px;
        background: $color-tundora;
        content: "";
      }
    }
    &__row:first-child &__th--large {
      display: none;
    }
    &__col--actions {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    &__row:first-child &__col--actions &__th {
      display: block;
      visibility: hidden;
    }
    &__td {
      padding: 0 20px;

      &--title {
        padding-top: 20px;
        font-weight: bold;
        font-size: $font-size-large;
      }
    }
  }

  @include media(">=tablet") {
    &__row:first-child &__th--hidden {
      visibility: hidden;
    }
    &__td {
      padding: 20px;
    }
  }
}
