.portal-item {
  margin-bottom: 10px;
  background: $color-tundora;
  padding: 30px;

  &__title {

  }

  &__menu {
    float: right;
    width: 1em;
  }

  &__images, &__attachments {
    @include list($margin: 2rem 0);
  }

  &__images {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
  }

  &__image {
    flex: 0 0 50%;

    &:nth-child(1) {
      padding-right: 10px;
    }
    &:nth-child(2) {
      padding-left: 10px;
    }
    &:only-child {
      padding: 0;
      flex: 1;
    }

    img {
      //width: 100%;
      height: auto;
    }
    a {
      display: flex;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      &[data-more] {
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba($color-mineshaft, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: rem(80);
          content: attr(data-more);
        }
      }
    }
  }

  &__attachment {
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    background: $color-mineshaft;
    padding: 15px;
    text-decoration: none;
    font-size: $font-size-larger;
    color: rgba($color-white, 0.5);
  }
  &__attachment-name {
    display: flex;
    align-items: center;
    font-weight: bold;

    &::before {
      margin-right: 20px;
      width: rem(26);
      height: rem(36);
      background: svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 36"><g fill="none" fill-rule="evenodd" stroke="#979797" stroke-width="2"><path d="M19.5 1H1v34h26V9.8L19.5 1z"/><path d="M18 4v6.8h6"/></g></svg>') no-repeat 100%;
      content: "";
    }
  }
  &__attachment-type {
    padding-right: 10px;
    font-weight: normal;
    text-transform: uppercase;
    font-size: inherit;
  }

  &__video {
    max-width: 100%;
  }

  &__footer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    color: rgba($color-white, 0.5);

    p {
      margin: 0;
    }
  }
}
