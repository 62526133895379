.obfuscated {
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;

  &--link {
    color: $color-yellow;
    text-decoration: underline;
  }
}
