@keyframes show-profile {
  0% {max-height: 0}
  95% {max-height: 125vh}
  100% {max-height: none}
}
@keyframes hide-profile {
  0% {max-height: 125vh}
  100% {max-height: 0}
}

.profile { // details, also .details
  font-size: $font-size-larger;

  $mobile-left-spacing: 35px;

  &__toggle { // summary, also .details__toggle
    border: solid $color-tundora;
    border-width: 6px 0 0;
    padding: 30px 0 30px $mobile-left-spacing;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &::before {
      top: 27px;
    }
  }

  //& + & {
  //  margin-top: 30px;
  //}

  &[open] {
    padding-bottom: 30px;
  }

  &__collapsible {
    display: flex;
    flex-flow: column;
  }

  &[open] &__collapsible {
    animation-name: show-profile;
  }
  &[data-closing] &__collapsible {
    animation-name: hide-profile;
  }

  &__name { // h2
    margin: 0;
    font-size: $font-size-large;
    flex: 1;
  }

  &__awards {
    display: flex;
    flex-flow: row-reverse wrap;
    align-items: center;
  }
  &__award-images {
    margin-top: 5px;
    flex: 1 0 100%;
    display: flex;

    &--employee {
      display: none;
    }
  }

  &__content {
    flex: 1;
    margin-left: $mobile-left-spacing
  }

  &__avatar { // figure
    flex: 0 0 180px;
    margin: 0 60px 1em $mobile-left-spacing;
    width: 180px;
    height: 180px;
    background: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__details { // dl
    margin: 0;
    padding: 0;

    a {
      color: $color-yellow;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  &__detail { // dt
    @include accessible-hidden;

    &-value { // dd
      margin: 0 0 0.5em;
    }
  }

  &__associations {
    @include list;
    display: flex;

    a {
      margin-right: 2px;
      width: 40px;
      height: 40px;
      background: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 20px;
    }
  }

  &__section {
    margin: rem(50) 0 rem(20);
    font-size: $font-size-larger;
    color: $color-gray;
  }

  &__list {
    @include list;
    border-bottom: 3px solid $color-tundora;

    &-item {
      border-top: 3px solid $color-tundora;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    dd {
      margin: 0;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__productions {
    //
  }

  &--light &__toggle {
    border-color: $color-emperor;
  }

  @include media(">=tablet") {
    &__toggle {
      flex-wrap: nowrap;
      padding-left: 0;

      &::before {
        transform: translateX(calc(-100% - 18px));
      }
    }
    &__awards {
      order: 1;
    }
    &__award-images {
      margin: 0 10px 0 auto;
      flex: 0;

      &--employee {
        display: flex;
      }
    }
    &__avatar, &__content {
      margin-left: 0;
    }
    &__collapsible {
      flex-flow: row wrap;
    }
    &__lists {
      flex: 1 0 100%;
      max-width: 100%;
    }
  }

  @include media(">=tablet-landscape") {
    padding-left: 35px;
  }
}

.award { // figure
  margin: 0 1px 0 0;
  border-radius: 50%;
  background: $color-white;
  padding: 0;
  width: rem(40);
  height: rem(40);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: auto;
    max-width: 30px;
    max-height: 30px;
  }
}
