.call-to-action {
  margin: 0;

  & + & {
    margin-top: 2px;
  }

  & &__link {
    appearance: none;
    border: 0;
    margin-right: 15px;
    display: inline-block;
    background: $color-yellow;
    padding: 10px 20px;
    text-decoration: none;
    font: inherit;
    font-size: $font-size-normal;
    color: $color-black;
    cursor: pointer;

    &--inverted {
      background: $color-black;
      color: $color-white;
    }

    &--white {
      background: $color-white;
      color: $color-black;
    }

    & + & {
      margin-left: 15px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__link-separator {
    // text in between links/buttons
    margin-right: 15px;
  }
}
