.image-list {
  @include list;
  display: flex;
  flex-flow: row wrap;

  gap: 15px;
  line-height: 0;

  li, a {
    max-width: 410px;
    flex: 1;
    display: flex;
  }

  img {
    flex: 1;
    object-fit: cover;
    max-height: 200px;


    max-width: none;

    $outer-margin: 110px;
    --container: calc(100vw - #{$outer-margin});
    // average number of items per row:
    --items: calc((var(--container) / 330));
    --grow-factor: calc(1 + (1 / var(--items)));
    height: calc(180px * var(--grow-factor));
  }
}
