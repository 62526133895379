.button, button {
  appearance: none;
  display: inline-block;
  margin: 0;
  outline: none;
  border: 0;
  background: $color-white;
  padding: 10px 30px;
  white-space: nowrap;
  text-decoration: none;
  font: inherit;
  font-size: $font-size-normal;
  color: $color-black;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover, &:focus {
    text-decoration: underline;
  }

  &[disabled] {
    background: $color-cod-gray;
    text-decoration: none;
    color: $color-dove-gray;
    cursor: default;
  }
}

.button--primary, button {
  background: $color-yellow;
}

.button {
  &--secondary {
    background: $color-cod-gray;
    color: $color-white;
  }
  &--larger {
    font-size: $font-size-larger;
  }

  &--add {
    padding-left: 20px;

    &::before {
      margin-right: 5px;
      vertical-align: middle;
      line-height: 0.5;
      font-size: 1.5em;
      text-decoration: none;
      display: inline-block;
      content: "+";
    }
  }

  &--back {
    padding-left: 20px;
    &::before {
      margin-right: 5px;
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border: solid $color-black;
      border-width: 0 0 3px 3px;
      transform: rotate(45deg);
      content: "";
    }
  }

  &--play {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }

  &--icon {
    width: rem(50);
    height: rem(50);
    padding: 0;
    background: center center / 30px 30px $color-tundora no-repeat;
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    font-size: $font-size-tiny;
    color: $color-white;

    &::before {
      flex: 1 0 115%;
      content: "";
    }

    &-document {
      background-image: svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 26"><g fill="none" fill-rule="evenodd" stroke-width="2"><path fill="#000" stroke="#000" d="M15 1H2a1 1 0 00-1 1v22a1 1 0 001 1h18a1 1 0 001-1V7.4L15 1z"/><path stroke="#FFF" stroke-linecap="round" d="M14.1 3v6h5.6"/></g></svg>');
    }
    &-image {
      background-image: svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20"><g fill="none" fill-rule="evenodd" stroke-width="2"><rect width="28" height="18" x="1" y="1" fill="#000" stroke="#000" rx="2"/><path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" d="M6 14l3.8-5.6c.3-.4.8-.5 1.2-.3.2 0 .3.2.3.3l1.7 2.4M11 14l.8-1.2 4.5-6.4a1 1 0 011.1-.3c.2 0 .3.2.3.3L23 14"/></g></svg>');
    }
    &-video {
      background-image: svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20"><g fill="none" fill-rule="evenodd" stroke-width="2"><rect width="28" height="18" x="1" y="1" fill="#000" stroke="#000" rx="2"/><path stroke="#FFF" d="M12 5.8v8.4l7-4.3-7-4.1z"/></g></svg>');
    }
    &-producers {
      background-image: svg-data-url('<svg width="28" height="25" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M.725 7.793L25.451.728A2 2 0 0128 2.651v15.787a2 2 0 01-2.485 1.94L.757 14.19A1 1 0 010 13.22V8.754a1 1 0 01.725-.961z" fill="#000"/><path stroke="#FFF" stroke-width="2" stroke-linecap="round" d="M5 9v4"/><path d="M8.147 18.77V22a2 2 0 002 2h6.865a2 2 0 002-2v-1h0" stroke="#111" stroke-width="2" stroke-linecap="round"/></g></svg>');
    }
  }
}
