.table {
  width: 100%;
  border: 0;
  border-collapse: collapse;
  color: rgba($color-white, 0.5);

  td, th {
    text-align: left;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  thead {
    th {
      padding: 0 20px;
      font-weight: normal;
      font-size: $font-size-small;
    }
  }

  tbody {
    background: $color-tundora;

    td, th {
      padding: 20px;
      font-size: $font-size-large;
      > a {
        color: $color-white;
      }
    }
  }

  &__menu-cell {
    width: 2.5em;
  }
}
