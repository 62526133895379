@use "sass:math";

.highlighted-locations {
  $height: 280px;
  $width: 780px;

  order: -1;
  margin-top: 30px;
  margin-bottom: -$height + 50px;
  overflow: hidden;
  min-height: 420px;

  &__content { // also .content.content--wide
    padding: 0;
    //max-height: 380px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: $height;
  }

  &__container {
    width: 100%;
    max-width: $width;
    //min-height: 300px;
    max-height: 380px;
    perspective: 100vw;
    perspective-origin: 50% 50%;
    padding-top: math.div(380, 780) * 100%;
  }

  &__list {
    @include list;
    position: absolute;
    top: 0;
    width: 100%;
    transform-style: preserve-3d;
    transform: translateZ(-100px);
  }

  &__item {
    position: absolute;

    &:nth-child(1) {
      transform: translateZ(100px);
    }
    &:nth-child(2) {
      transform: translateX(-75px) translateZ(0);
    }
    &:nth-child(3) {
      transform: translateX(75px);
    }
  }
  &__figure {
    margin: 0;
    padding: 0;
    display: flex;
  }
  img {
    width: 100%;
    min-width: 500px;
    height: auto;
  }
  &__caption {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }

  @include media("<tablet-landscape") {
    &__content {
      transform: translateX(25%);
    }
  }

  @include media(">=tablet") {
    $height: 380px;
    margin-bottom: -$height + 50px;

    &__content {
      height: $height;
    }
    &__container {
      perspective-origin: 50% 190px;
      perspective: $width;
    }
  }
  @include media(">=tablet-landscape") {
    $height: 380px;
    margin-bottom: -$height + 50px;

    &__content {
      height: $height;
    }
  }
}
