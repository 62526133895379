.actions-list {
  @include list;

  @include media("<tablet") {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    .button {
      width: 100%;
      padding: 15px 0;
      text-align: center;
    }
  }
  @include media(">=tablet") {
    float: right;
  }
}
