@use "sass:math";

.form {
  &__row {
    margin: 0 -5px;
    border: 0;
    padding: 0;
    display: flex;
    flex-flow: column;
    //gap: 10px; // no safari support :-(
    //align-items: flex-end;

    &--add {
      & > .button {
        margin-bottom: 15px;
      }
    }

    dt, dd {
      margin: 0;
    }
    .button {
      align-self: flex-end;
    }
  }

  &__row &__field,
  &__row &__field-margin {
    margin-left: 5px;
    margin-right: 5px;
  }

  &__row &__field {
    margin-bottom: 5px;

    &--grow {
      max-width: none;
    }
    &--left {
      margin-right: 0;
      border-radius: 5px 0 0 5px;
      border-right: 0;
    }
    &--right {
      flex: 0;
      justify-content: center;
      margin-left: 0;
      border-radius: 0 5px 5px 0;
      border-left: 0;
    }
  }
  &__row &__field--left:focus ~ &__field--right,
  &__row &__field--left:focus-within ~ &__field--right {
    border-color: $color-yellow;
  }

  &__column {
    margin: 0 0 15px;
  }
  &__column &__field {
    border-radius: 0;
    margin-bottom: 0;

    &--start {
      border-radius: 5px 5px 0 0;
    }
    &--end {
      border-radius: 0 0 5px 5px;
    }
  }

  $single-field-max-width: 476px;

  &__field {
    flex: 1;
    position: relative;
    margin: 0 0 15px;
    border-radius: 5px;
    background: $color-cod-gray;
    padding: 10px 20px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: $color-white;
    border: 1px solid transparent;

    &:not(&--no-focus) {
      &:focus, &:focus-within {
        border-color: $color-yellow;
      }
    }

    &--read-only {
      border: 1px solid $color-cod-gray;
      background: transparent;
    }

    &--offset {
      margin-top: 3rem;
    }

    &--clear {
      margin-bottom: 0;
      border-radius: 0;
      max-width: none;
      background: transparent;
      padding-left: 0;
      color: inherit;

      &:focus, &:focus-within {
        border-bottom-color: $color-black;
      }
    }

    &--large {
      font-size: $font-size-large;
      font-weight: bold;
    }
    &--larger {
      font-size: $font-size-larger;
    }

    input, textarea, select {
      appearance: none;
      outline: none;
      border: 0;
      width: 100%;
      background: transparent;
      padding: 0;
      font: inherit;
      font-weight: bold;
      caret-color: $color-yellow;
      color: inherit;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 0;
        -webkit-text-fill-color: $color-white;
        -webkit-box-shadow: 0 0 0 1000px $color-cod-gray inset;
        transition: background-color 5000s ease-in-out;
        font: inherit;
      }
    }

    input[type=radio], input[type=checkbox] {
      width: auto;
    }

    select {
      background: inherit;
      color: inherit;
      cursor: pointer;
    }

    button:disabled {
      background: $color-mineshaft;
    }

    &--image {
      margin-bottom: 45px;
      background: transparent;
      padding: 0;

      .form__label {
        position: absolute;
        z-index: 1;
        margin: 10px 0 0 15px;
      }
    }
    &--number {
      width: math.div($single-field-max-width, 2);
    }
  }

  &--dark &__field {
    background-color: $color-mineshaft;

    button:disabled {
      background: $color-black;
      color: $color-emperor;
    }
  }

  &__label {
    display: block;
    padding-bottom: 10px;
    font-size: $font-size-tiny;
    font-weight: normal;
    cursor: pointer;
  }
  &__field--read-only &__label {
    cursor: auto;
  }

  &__field--hidden-label {
    justify-content: center;
  }
  &__field--hidden-label &__label {
    @include accessible-hidden;
  }
  &__field--shaded &__label {
    color: rgba($color-white, 0.5);
  }
  &__field--inline-label {
    flex-flow: row;
    align-items: center;

    .form__label {
      margin-right: 10px;
      padding-bottom: 0;
      white-space: nowrap;
      font-size: inherit;
    }
  }

  &__choice-field {
    margin: 0 0 45px;
    max-width: $single-field-max-width;
  }
  &__choice-field &__help {
    margin-left: 45px;
  }
  &__choice-list { // ul
    @include list($margin: 0 0 1rem);

    & > li {
      margin-bottom: 1rem;
    }
  }
  &__choice { // label
    cursor: pointer;
    position: relative;

    input {
      @include accessible-hidden;
      // offset to correctly position Chrome's "required" bubbles:
      top: 30px;
      left: 15px;
    }

    &-label {
      display: block;
      position: relative;
      padding-left: 45px;
      //display: flex;
      //align-items: center;

      &::before, &::after {
        display: inline-flex;
        vertical-align: middle;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        content: "";
      }
      &::before {
        margin-left: -45px;
        margin-right: 15px;
        border-radius: 3px;
        background-color: $color-black;
      }
      &::after {
        order: -1;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    input:checked + &-label::after {
      background: 50% 50% / 50% no-repeat svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path fill="none" fill-rule="evenodd" stroke="#FFF" stroke-width="2" d="M.8 6.1L7 14.7l9-13.6"/></svg>');
    }
  }

  // overrides
  &__field {
    &--wide {
      max-width: none;
    }
  }
  &__help {
    margin: 10px 0;
    font-size: $font-size-tiny;
  }
  &__errors {
    @include list;
    color: $color-yellow;
  }
  &__label-action {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: $font-size-tiny;
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  &__item-list { // dl
    @include list;
  }

  // deletable rows:
  &__row--deletable,
  &__row--deletable &__column {
      position: relative;
  }
  &__delete-input {
    @include accessible-hidden;
  }
  &__delete-label {
    order: 1;
    align-self: center;
    flex: 0 85px;
    text-align: right;
    padding-left: 1rem;
    overflow: hidden;
    cursor: pointer;
  }
  &__delete-input:checked + &__delete-label {
    text-indent: 100%;
    width: 81px;
    position: relative;

    &::before {
      position: absolute;
      right: 0;
      text-indent: 0;
      content: attr(data-undo);
    }

    & ~ * {
      color: rgba($color-white, 0.25);
    }
    & ~ *:not(&__delete-label) {
      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-bottom: 2px solid $color-yellow;
        content: "";
      }
      .form__field {
        background-color: rgba($color-black, 0.2);
        color: inherit;
      }
    }
  }

  &__row-title {
    display: flex;
    align-items: center;
    padding-left: 5px;
    font-size: $font-size-larger;
    font-weight: bold;
    color: $color-dove-gray;
  }
  &__column {
    flex: 1;

    &--small {
      flex-grow: 0;
      flex-basis: 300px;
    }
    &--smaller {
      flex-grow: 0;
      flex-basis: 40%;
    }
  }

  &__buttons { // .button-row
    margin: 30px 0 0;
    max-width: $single-field-max-width;
  }

  &__input {
    &--hidden {
      @include accessible-hidden;
    }
  }

  @include media(">=tablet") {
    &__field {
      max-width: $single-field-max-width;

      &--wide {
        max-width: none;
      }
    }
    &__row {
      flex-flow: row;
    }
    &__row &__field {
      margin-bottom: 15px;

      &--shrink {
        flex: 0;
        min-width: 30%;
      }
      &--shrink-less {
        flex: 0;
        min-width: 37%;
      }
    }
  }
}

.image-input {
  display: flex;
  align-items: flex-end;

  &__initial, img {
    width: 120px;
    height: 120px;
    border-radius: 4px;
  }

  img {
    position: absolute;
    left: 0;
  }

  &__initial {
    background: $color-black;
    //overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    position: relative;

    &--blank {
      //@include accessible-hidden;
      text-indent: 200%;

      &::after {
        position: absolute;
        z-index: 1;
        right: -10px;
        bottom: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: $color-yellow;
        font-size: 35px;
        text-indent: 0;
        color: $color-black;
        content: "+";
      }
    }
  }

  &__clear {
    margin-left: 20px;
    font-size: $font-size-small;
  }

  input[type=file] {
    @include accessible-hidden;
  }
}
