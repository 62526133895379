/// Site footer

.site-footer {
  &__content {
    background: transparent;
  }

  &__text {
    margin-bottom: 2rem; // 70px
    white-space: pre-line;
    font-size: $font-size-large;
    max-width: 49ch;

    :first-child {
      margin-top: 0;
    }
  }

  &__logos {
    margin: 0 0 2rem;
    display: flex;
    flex-flow: column;

    img {
      max-width: 204px;
    }
  }

  &__menu-section {
    margin: 0 0 3rem;
    padding: 0;
    font-style: normal;
  }

  &__bottom {
    justify-content: space-between;
  }
  &__bottom &__menu-section {
    //flex: 1;
  }

  h2 {
    font-size: $font-size-larger;
  }

  ul {
    @include list;
  }

  & ul.site-footer__flat-menu {
    margin-bottom: 1rem;
  }

  &__flat-menu + &__menu-title {
    margin-top: 3rem;
  }

  @include media(">=tablet") {
    padding: 0 0 80px;

    &__top {
      display: flex;

      &-item {
        flex: 1;
      }
    }

    &__text {
      //margin-bottom: 90px;
    }

    &__logos {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    &__menu {
      display: flex;
      flex-flow: column;
    }

    &__bottom {
      display: flex;
      flex-flow: row wrap;
      //justify-content: space-between;
    }

    //&__menu-section {
    //  &:first-child {
    //    width: 100%;
    //  }
    //}
  }

  @include media(">=tablet-landscape") {
    h2 {
      display: flex;
      min-height: 2.2em;
      align-items: flex-end;
    }

    &__menu {
      flex-flow: row;
    }
    &__menu > &__menu-section {
      flex-basis: 30%;
    }
    &__bottom {
      flex: 1;
    }
  }

  @include media(">=desktop") {
    padding: 0 0 80px;

    &__logos {
      flex-flow: row;
      justify-content: flex-end;
    }

    &__bottom &__menu-section {
      //&:first-child {
      //  flex: 1;
      //  width: auto;
      //}

      //&--fixed {
      //  flex-basis: 204px;
      //}
    }
  }
}
