.nav-link {
  margin: 1rem 0 3rem;
  font-size: $font-size-larger;

  &__link {
    text-decoration: none;
    color: $color-white;

    &:hover {
      text-decoration: underline;
    }

    b, strong {
      color: $color-yellow;
    }

    &--back::before {
      display: inline-block;
      margin-right: 5px;
      border: solid $color-yellow;
      border-width: 0 0 2px 2px;
      transform: rotate(45deg);
      width: 8px;
      height: 8px;
      content: "";
    }
  }
}
