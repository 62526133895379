.portal { // body
  // stretch full height:
  display: flex;
  flex-flow: column;

  main {
    flex: 1;

    & > .site-container {
      flex: 1;
    }
  }

  &__top-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: $color-yellow;
    padding: 0 40px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-black;

    a {
      color: inherit;
    }
  }

  //@include media(">=tablet-landscape") {
  //  &__header-title-offset {
  //    margin-top: 30px;
  //  }
  //}
}
