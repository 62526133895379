.columns {
  //margin: 120
  &__image {
    height: auto;
    max-width: 95%;
  }

  @include media(">=tablet") {
    margin: 80px 0;
    display: flex;
    justify-content: space-between;

    &--reversed {
      flex-flow: row-reverse;
    }

    &__column {
      max-width: 300px;
    }

    &__image {
      margin: 0 -30px;
      max-width: calc(100% + 60px);
    }
  }
  @include media(">=tablet-landscape") {
    &__image {
      margin: 0 -50px;
      max-width: calc(100% + 120px);
    }
  }
}
