.autocomplete {
  //margin: 0;
  border-radius: 5px;
  background: $color-cod-gray;
  padding: 0 21px 10px 21px;
  line-height: 2;
  transform: translate(-21px, 13px); // 20px field padding + 1px field border
  z-index: 5;

  > * {
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  > .selected, :hover, :focus {
    font-weight: bold;
  }

  .empty-result {
    padding-top: 10px;
    justify-content: space-between;
  }
}
