.teaser {
  margin: 0 0 20px;
  background: $color-yellow;
  padding: 25px 30px;
  font-size: $font-size-normal;
  color: $color-black;

  &__title {
    margin: 0 20% 0 0;
    font-size: $font-size-large;
  }

  @include media(">=tablet") {
    margin-bottom: 20px;
    padding: 45px 50px;
    font-size: $font-size-larger;

    &--small {
      font-size: $font-size-normal;
    }

    &__title {
      margin: 0 20% 0 0;
      font-size: $font-size-huge;
    }
  }
}
