.production-roles { // .expandable
  $max-height: 3 * 52px;

  &.expandable {
    min-height: $max-height;
  }

  @keyframes expand {
    0% {max-height: $max-height;}
    95% {max-height: 125vh}
    100% {max-height: none}
  }
  @keyframes collapse {
    0% {max-height: 100vh}
    100% {max-height: $max-height}
  }

  margin-bottom: 1rem;
  font-size: $font-size-larger;

  &__expand {
    margin-bottom: 2rem;
  }

  &--collapsed {
    max-height: $max-height;
  }

  &__list {
    @include list($margin: 2em 0 1rem);
  }
  &__section {
    border-bottom: 2px solid $color-tundora;
    color: rgba($color-white, 0.6);
  }
  &__item {
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 2px solid $color-tundora;
    padding: 10px 0;
  }
  &__delete-label {
    order: 1;
    align-self: center;
    flex: 0 85px;
    text-align: right;
    padding-left: 1rem;
    overflow: hidden;
    cursor: pointer;
  }
  &__delete-input {
    @include accessible-hidden;
  }
  &__delete-input:checked + &__delete-label {
    text-indent: 100%;
    position: relative;

    &::before {
      position: absolute;
      right: 0;
      text-indent: 0;
      content: attr(data-undo);
    }

    & ~ * {
      color: rgba($color-white, 0.25);
    }
    & ~ .production-roles__part {
      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-bottom: 2px solid $color-yellow;
        content: "";
      }
      .form__field {
        background-color: rgba($color-black, 0.2);
        color: inherit;
      }
    }
  }
  &__part {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;

    &--year {
      flex: 0 0 6rem;
    }
    &--role {
      justify-content: flex-end;
      font-size: $font-size-small;
      color: rgba($color-white, 0.6);
    }
  }
}
