main {
  position: relative;
  //margin-top: 195px;
  display: flex;
  flex-flow: column;

}
// last container elements before the footer:
.modal > :last-child {
  padding-bottom: 50px;
}

.site-container {
  background-color: $color-mineshaft;
  overflow: hidden;
  padding: 0 20px;

  &--clear {
    background: transparent;
  }

  &::after {
    margin-top: 60px;
    display: block;
    clear: both;
    content: "";
  }
}

.content {
  // content container
  //margin: 0 20px;
  padding-top: 75px;
  //overflow: hidden;
  //padding: 75px 55px;
}

.headline {
  //position: absolute;
  //left: 50%;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: $max-outer-width + 110px;
  margin: 0 auto -0.65em;
  padding-left: 20px;
  line-height: 1;
  font-size: rem($headline-font-size-mobile);
  //text-transform: lowercase;
  white-space: pre-line;
  //transform: translate(-50%, calc(-100% + 0.4em));

  &--normal {
    white-space: nowrap;
  }

  &__prefix {
    // "Take"
    display: block;
    width: 4ch;
    //margin-bottom: -0.2em;
    line-height: 0.7;
    overflow: hidden;
    transform: rotate(-8deg);
    transform-origin: bottom left;
    color: $color-yellow;
    clip-path: inset(0 0 30%);
  }

  &__line {
    display: block;
  }
}

@include media(">=mobile-landscape") {
  main {
    //padding: 0 55px;
  }
}

@include media(">=tablet") {
  main {
    //margin-top: 290px;
  }

  .site-container::after {
    margin-top: 80px;
  }

  .content, .modal-content {
    margin: 0 auto;
    max-width: 650px;
  }

  .content--wide {
    margin: 0 35px;
    max-width: $max-outer-width;
  }

  .content--full {
    margin: 0 35px;
    max-width: none;
  }

  .headline {
    //left: 55px;
    padding-left: 55px;
    font-size: rem($headline-font-size-portrait);
  }
}

@include media(">=tablet-landscape") {
  .site-container::after {
    margin-top: 110px;
  }

  .content:not(.content--wide) {
    max-width: 940px;
    padding-left: 220px;
    //margin-left: 220px;
  }

  .headline {
    font-size: rem($headline-font-size-landscape);
  }
}

@include media(">=content-centered") {
  .content--wide {
    margin: 0 auto;
    width: 100%;
  }
}
