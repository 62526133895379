.heading {
  padding: 0 0 20px;

  &--offset {
    margin-top: 2rem;
  }
  &--offset-4 {
    margin-top: 4rem;
  }

  &--underlined {
    border-bottom: 6px solid $color-tundora;
  }

  &--no-push {
    margin-bottom: 0;
  }
}
