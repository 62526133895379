.tags {
  @include list;
  display: flex;
  flex-flow: row wrap;

  & > * {
    margin: 0 10px 10px 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &--aligned-end {
    & > * {
      margin-left: 10px;
      margin-right: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.tag {
  background: $color-black;
  padding: 0.5em 1em;
  text-decoration: none;
  font-size: $font-size-larger;
  font-weight: bold;
  color: $color-white;
  display: inline-flex;

  a {
    color: inherit;
    text-decoration: none;
  }

  &__remove {
    margin-left: 1ch;
    position: relative;
    overflow: hidden;
    display: inline-block;
    max-width: 1.5ch;
    white-space: nowrap;
    text-indent: 2ch;

    &::before {
      position: absolute;
      transform: translateY(-0.45em) rotate(45deg);
      left: -1.3ch;
      font-size: 1.4em;
      content: "+";
    }
  }

  &--inverted {
    background: $color-yellow;
    color: $color-black;
  }
}
