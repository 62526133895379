.action-header {
  background-color: $color-yellow;
  padding: 40px 55px;
  color: $color-black;

  @at-root .modal & {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__title {
    margin: 0 0 1em;
  }

  @include media(">=tablet-landscape") {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    &__title {
      width: 100%;
    }

    &__content {
      display: flex;
    }

    &__text {
      flex: 1 1 50%;

      p {
        margin: 0;

        & + p {
          margin: 0 0 1em;
        }
      }
    }
    &__cta {
      flex: 1 1 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
