.form__field select, .select { // select
  appearance: none;
  margin: 0;
  border: 0;
  background: transparent right center / 1em no-repeat svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 9"><path fill="none" stroke="#fff" stroke-width="2" d="M1 1l6 6 6-6"/></svg>'); // url(static("img/select-arrow.svg"));
  padding: 0 1em 0 0;
  outline: none;
  font: inherit;
  font-weight: bold;
  color: inherit;
  cursor: pointer;
}
