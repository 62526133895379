.video { // <div>
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  &__video { // <video>
    max-width: 100%;
  }

  &__play, &__play::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__play {
    overflow: hidden;
    text-indent: 500%;

    &::before {
      width: 35px;
      height: 30px;
      background: svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 25"><path fill="none" fill-rule="evenodd" stroke="#2A2929" stroke-linejoin="round" stroke-width="2" d="M1 1v23l26-11.5z"/></svg>') no-repeat 100%;
      content: "";
    }
  }
}
