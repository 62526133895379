@keyframes show-menu {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes hide-menu {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-20px);
    opacity: 0;
  }
}
.menu { // details
  position: relative;
  width: 0.5em;

  &__toggle { // summary
    outline: none;
    overflow: hidden;
    white-space: nowrap;
    list-style: none;
    cursor: pointer;

    //&::-webkit-details-marker,
    &::marker {
      display: none;
      content: "";
    }

    &::before {
      display: inline-block;
      width: 100%;
      background: svg-data-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 26"><g fill="#FFF" fill-rule="evenodd"><circle cx="3" cy="3" r="3"/><circle cx="3" cy="13" r="3"/><circle cx="3" cy="23" r="3"/></g></svg>') no-repeat right;
      content: "\22EE";
      color: transparent;
    }
  }

  &__list { // ul
    position: absolute;
    top: 100%;
    right: 0;
    @include list($margin: 3px 0 0, $padding: 15px 0);
    background: $color-white;
    line-height: 2;

    a {
      display: block;
      padding: 0 30px;
      text-decoration: none;
      color: $color-black;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }

  $speed: 0.3s;

  &[open] &__list {
    animation: show-menu $speed ease-in-out;
  }
  &[data-closing] &__list {
    animation: hide-menu $speed ease-out;
  }
}
