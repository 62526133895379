.header { // header
  margin: 0 0 2rem;
  border-bottom: 6px solid $color-tundora;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &__heading {
    margin: 0;
  }
  &__meta {
    margin: 0;
  }
}
