.update {
  background: $color-cod-gray;
  padding: 30px;
  overflow: hidden;
  font-size: $font-size-larger;
  color: $color-white;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .rich-text {
    margin-bottom: 30px;

    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
}
