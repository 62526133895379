.contact-card { // aside
  margin: 80px 0;
  padding: 0;
  //font-size: $font-size-normal;

  &__title {
    margin-top: 0;
  }

  &__address {
    p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__person { // figure
    //margin: 0 -55px 0 -30px;
    margin: 1rem 0 0;
    padding: 0;
    background: $color-white;
    color: $color-black;
    //display: flex;
    //flex-flow: row-reverse;
    //align-items: flex-start;
    //justify-content: space-between;

    a {
      display: block;
      color: $color-black;
      text-decoration-color: $color-yellow;
    }
  }

  &__image {
    float: right;
    max-width: 30%;
    width: 100px;
  }

  &__caption {  // figcaption
    padding: 5px 20px 5px 35px;
    align-self: center;
  }

  &__name {
    font-weight: bold;
  }

  &--inverted {
    background: $color-black;
  }
  &--inverted &__person {
    background: transparent;
    color: $color-white;

    a {
      color: $color-yellow;
    }
  }
  &--inline {
    padding: 30px 55px;
  }
  &--inline &__title {
    margin: 0;
  }
  &--inline &__caption {
    padding: 0;
  }
  &--inline &__person {
    margin: 0;
    padding: 0;
    display: block;
  }

  @include media(">=mobile-landscape") {
    &__person {
      //margin: 0 -30px 0 -30px;
      //margin-right: -30px;
    }
  }

  @include media(">=tablet") {
    //margin: 0 auto;
    //max-width: 720px;
    display: flex;
    flex-flow: row wrap;
    //align-items: flex-start;
    justify-content: space-between;

    &__title {
      flex: 1 1 100%;
    }

    &__person {
      margin: 0 0 0 20px;
      flex: 1 1 50%;

      &:first-child {
        margin: 0;
      }
    }

    &__caption {
      padding: 5px 35px;
    }

    &--inline {
      flex-flow: row;
    }
    &--inline &__person {
      flex: 0 0 50%;
    }
  }
}
