@keyframes show-details {
  0% {max-height: 0}
  95% {max-height: 100vh}
  100% {max-height: none}
}
@keyframes hide-details {
  0% {max-height: 100vh}
  100% {max-height: 0}
}

@keyframes fadein-details {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
@keyframes fadeout-details {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

.details {
  &__toggle { // summary
    outline: none;
    position: relative;
    list-style: none;
    cursor: pointer;

    // &::-webkit-details-marker,
    &::marker {
      display: none;
      content: "";
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      //margin-right: 20px;
      font-size: 1.85rem;
      line-height: 1.1;
      font-weight: bold;
      content: "+";
    }
  }

  &[open] &__toggle {
    &::before {
      line-height: 0.95;
      margin-right: 21px;
      content: "\2013"; // &ndash;
    }
  }

  &__collapsible {
    overflow: hidden;
  }
  &[open] &__collapsible {
    animation: show-details 0.5s ease-in-out;
  }
  &[data-closing] &__collapsible {
    animation: hide-details 0.5s ease-out;
  }

  &--menu {
    position: relative;
  }
  &--menu &__toggle {
    //transition: 0.2s ease-in-out;
    //transition-property: background-color, color;
    &::before {
      display: none;
    }
  }
  &--menu[open] &__toggle {
    background-color: $color-white;
    color: $color-black;
  }
  &--menu &__collapsible {
    position: absolute;
    background-color: $color-white;
    width: 100%;
    color: $color-black;
    animation: none;
  }
  &--menu[open] &__collapsible {
    animation-name: none;
  }
  &--menu[data-closing] &__collapsible {
    animation-name: none;
  }
}
