.production-list {
  $max-height: 4 * 53px;

  @keyframes expand {
    0% {max-height: $max-height;}
    95% {max-height: 125vh}
    100% {max-height: none}
  }
  @keyframes collapse {
    0% {max-height: 100vh}
    100% {max-height: $max-height}
  }

  overflow: hidden;

  &:last-child {
    margin-bottom: 2em;
  }

  &__title { // dt
    font-weight: bold;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__year { // dd
    order: -1;
    flex: 0 0 6ch;
  }
  &__awards {
    justify-self: flex-end;
    flex: 0 0 9ch;
    text-align: right;
  }
  &__expand {
    position: relative;
    appearance: none;
    margin-top: 1em;
    outline: none;
    border: 0;
    background: none;
    padding: 0 0 0 1em;
    font: inherit;
    font-size: $font-size-normal;
    color: $color-yellow;
    cursor: pointer;

    &:last-child {
      margin-bottom: 2em;
    }

    &:hover, &:focus {
      text-decoration: underline;
    }

    &::before {
      position: absolute;
      left: 0;
      text-decoration: none;
      content: "+";
    }
  }

  &[data-expanding] {
    animation: expand 0.5s ease-in-out;
  }
  &[data-collapsing] {
    animation: collapse 0.5s ease-in-out;
  }
  &--collapsed {
    max-height: $max-height;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}
