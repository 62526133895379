@keyframes appear {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes vanish {
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
}
