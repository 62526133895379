.modal-form {
  &__header {
    overflow: hidden;
    background: $color-yellow;
    color: $color-black;
  }
  &__headline {
    margin: 6rem auto 2rem;
    max-width: $max-outer-width + 110px;

    &::before {
      position: absolute;
      margin-top: -2.5rem;
      font-weight: 600;
      font-size: $font-size-larger;
      content: var(--site-title, "netherlands film commission");
    }
  }
}
